import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Home() {
  const [isVisible, setIsVisible] = useState(() => {
    const savedState = localStorage.getItem("noticeBoardVisible");
    return savedState === null ? true : JSON.parse(savedState);
  });

  useEffect(() => {
    localStorage.setItem("noticeBoardVisible", JSON.stringify(isVisible));
  }, [isVisible]);

  return (
    <>
      <main>
        <div
          id="carouselExampleIndicators"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row g-1">
                <div className="col-lg-6 col-12">
                  <img
                    src="/assets/images/banner/2.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/1.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row g-1">
                <div className="col-lg-3 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/3.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/4.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-3 col-12">
                  <img
                    src="/assets/images/banner/5.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row g-1">
                <div className="col-lg-4 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/8.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-4 col-12">
                  <img
                    src="/assets/images/banner/7.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-4 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/6.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row g-1">
                <div className="col-lg-6 col-12">
                  <img
                    src="/assets/images/banner/11.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-6 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/10.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row g-1">
                <div className="col-lg-3 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/12.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-6 col-12">
                  <img
                    src="/assets/images/banner/13.png"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-3 col-12 d-lg-block d-none">
                  <img
                    src="/assets/images/banner/9.jpg"
                    className="w-100 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>

            {isVisible && (
              <section className="d-lg-block d-none">
                <div className="container mt-8 d-flex justify-content-end">
                  <div className="col-4 ps-8 position-absolute z-5 me-8">
                    <div className="ps-5">
                      <div className="position-relative z-1">
                        <div className="container">
                          <div className="bg-white rounded-5 p-4">
                            <div className="d-flex justify-content-end">
                              <div className="m-n6">
                                <div className="bg-primary rounded-circle circle-md">
                                  <i
                                    className="bi bi-x h1 text-white cursor-pointer m-0"
                                    onClick={() => setIsVisible(false)}
                                  ></i>
                                </div>
                              </div>
                            </div>
                            <div className="bg-warning py-2 rounded-pill">
                              <div className="text-center">
                                <span className="text-dark text-uppercase fw-semibold">
                                  Important Notice{" "}
                                  <img
                                    src="/assets/images/new.gif"
                                    width="40"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="mt-4 pe-3">
                              <ol>
                                <li className="lh-sm mb-2 bg-light text-danger rounded-1 py-1 ps-1">
                                  Admissions open for the session 2025 - 26 for
                                  classes Pratyush (Nursery) to VIII
                                </li>
                                <li className="lh-sm mb-2 bg-light text-danger rounded-1 py-1 ps-1">
                                  Admission test will be conducted on 15/02/2025
                                  (Saturday)
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-n6">
                        <div className="container">
                          <div className="bg-dark rounded-bottom-5 px-4 py-lg-2 py-8 mb-5">
                            <div className="d-flex justify-content-between align-items-center mt-6">
                              <p className="text-white fw-normal lh-sm mt-3">
                                Parents, Teachers & Students Notice
                              </p>
                              <div className="ms-5 d-flex">
                                <Link
                                  to="/"
                                  className="btn btn-primary px-5 fs-6 py-1 my-1 rounded-pill fw-normal text-nowrap"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <section className="d-lg-block d-none">
          <div className="container z-5 position-relative">
            <div className="home-card-top border-0 mt-n6 border primary-gradient">
              <div className="row justify-content-around py-4 gy-4 gy-lg-0">
                <div className="col-3 text-center px-4 border-end-light py-1">
                  <Link to="/mission-vision" className="text-home-card-top m-0">
                    Mission & Vision
                  </Link>
                </div>
                <div className="col-3 text-center px-4 border-end-light py-1">
                  <Link className="text-home-card-top m-0">
                    Life @ Acharyakulam
                  </Link>
                </div>
                <div className="col-3 text-center px-4 border-end-light py-1">
                  <Link to="/chairman" className="text-home-card-top m-0">
                    Chairman's Message
                  </Link>
                </div>
                <div className="col-3 text-center px-4 py-1">
                  <Link
                    onClick={() => setIsVisible(!isVisible)}
                    className="text-home-card-top m-0"
                  >
                    Notice Board
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="d-lg-none d-block">
          <div className="container mt-n8">
            <div className="position-relative z-1">
              <div className="bg-white rounded-5 p-4">
                <div className="bg-warning py-2 rounded-pill">
                  <div className="text-center">
                    <span className="text-dark text-uppercase fw-semibold">
                      Important Notice{" "}
                      <img src="/assets/images/new.gif" width="40" />
                    </span>
                  </div>
                </div>
                <div className="mt-4 pe-3">
                  <ol>
                    <li className="lh-sm mb-2 bg-light text-danger rounded-1 py-1 ps-1">
                      Admissions open for the session 2025 - 26 for classes
                      Pratyush (Nursery) to VIII
                    </li>
                    <li className="lh-sm mb-2 bg-light text-danger rounded-1 py-1 ps-1">
                      Admission test will be conducted on 15/02/2025 (Saturday)
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="mt-n6">
              <div className="bg-dark rounded-bottom-5 px-4 py-lg-2 py-1 mb-5">
                <div className="d-flex justify-content-between align-items-center mt-6">
                  <p className="text-white fw-normal lh-sm mt-3">
                    Parents, Teachers & Students Notice
                  </p>
                  <div className="ms-5 d-flex">
                    <Link
                      to="/"
                      className="btn btn-primary px-5 fs-6 py-1 my-1 rounded-pill fw-normal text-nowrap"
                    >
                      View All
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          className="modal fade"
          id="messageAcharyajiModal"
          tabIndex={-1}
          aria-labelledby="messageAcharyajiModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg shadow modal-dialog-centered">
            <div className="modal-content border-0 rounded-5">
              <div className="position-relative d-flex justify-content-end">
                <div
                  type="button"
                  className="modal-close-button bg-white rounded-pill position-absolute m-3"
                  data-bs-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    width="20"
                  >
                    <path d="M12.12,10l4.07-4.06a1.5,1.5,0,1,0-2.11-2.12L10,7.88,5.94,3.81A1.5,1.5,0,1,0,3.82,5.93L7.88,10,3.81,14.06a1.5,1.5,0,0,0,0,2.12,1.51,1.51,0,0,0,2.13,0L10,12.12l4.06,4.07a1.45,1.45,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-2.56Z"></path>
                  </svg>
                </div>
              </div>
              <img
                src="/assets/images/acharyaji.jpg"
                alt="acharyaji"
                className="rounded-top-5 w-100 object-fit-cover"
                height="500"
              />

              <div className="d-flex justify-content-center">
                <div className="col-lg-9 col-10">
                  <div className="p-5 my-5">
                    <h2 className="fs-3 mb-0 h1 lh-1 mt-4">
                      Message from
                      <br />
                      <span className="colorful-text fs-2">
                        Dr. Acharya Balkrishna Ji
                      </span>
                    </h2>
                    <p className="my-4 fs-4">
                      "शिक्षा ” हमारी महान सांस्कृतिक विरासत का प्रमुख अंग है ।
                      यह शिक्षा भारत के महान ऋषि-मुनियों के द्वारा आशीर्वाद के
                      रूप में विश्व को दी गई सर्वोत्तम भेटों में से एक है | जिस
                      प्रकार किसी भी इमारत की मजबूती उसकी नींव की श्रेष्ठता पर
                      निर्भर करती है, उसी प्रकार विद्या जीवन का आधार है, क्योंकि
                      शरीर, मन एवं आत्मा तीनों स्तरों में सामंजस्य स्थापित करने
                      में यह सहयोगी है | विद्यार्थी किसी भी राष्ट्र का भविष्य
                      होते है | इनका सम्पूर्ण व्यक्तित्व अच्छी शिक्षा, उच्च
                      नैतिक मूल्यों तथा स्वस्थ जीवन शैली पर निर्भर होता है। भारत
                      के महान ऋषियों द्वारा मार्गदर्शित विद्या में ही मनुष्य की
                      अंतर्निहित शक्तियों का जागरण करने एवं उसके सर्वांगीण विकास
                      करने की संभावना को बढ़ाने की क्षमता है |
                    </p>

                    <div className="fs-4 mt-5">
                      <a
                        href="https://www.facebook.com/AcharyBalkrishna/"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-facebook fs-3"></i>
                      </a>
                      <a
                        href="https://twitter.com/i/flow/login?redirect_after_login=%2FAch_Balkrishna"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-twitter-x fs-3"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/user/acharyabalkrisha"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-youtube fs-3"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/acharyabalkrishna/?original_referer=https%3A%2F%2Facharyabalkrishna.com%2F/"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin fs-3"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="messageSwamiRamdevjiModal"
          tabIndex={-1}
          aria-labelledby="messageSwamiRamdevjiModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg shadow modal-dialog-centered">
            <div className="modal-content border-0 rounded-5">
              <div className="position-relative d-flex justify-content-end">
                <div
                  type="button"
                  className="modal-close-button bg-white rounded-pill position-absolute m-3"
                  data-bs-dismiss="modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    width="20"
                  >
                    <path d="M12.12,10l4.07-4.06a1.5,1.5,0,1,0-2.11-2.12L10,7.88,5.94,3.81A1.5,1.5,0,1,0,3.82,5.93L7.88,10,3.81,14.06a1.5,1.5,0,0,0,0,2.12,1.51,1.51,0,0,0,2.13,0L10,12.12l4.06,4.07a1.45,1.45,0,0,0,1.06.44,1.5,1.5,0,0,0,1.06-2.56Z"></path>
                  </svg>
                </div>
              </div>
              <img
                src="/assets/images/swamiji.jpg"
                alt="acharyaji"
                className="rounded-top-5 w-100 object-position-top object-fit-cover"
                height="500"
              />

              <div className="d-flex justify-content-center">
                <div className="col-lg-9 col-10">
                  <div className="p-5 my-5">
                    <h2 className="fs-3 mb-0 h1 lh-1 mt-4">
                      Message from
                      <br />
                      <span className="colorful-text fs-2">
                        Swami Ramdev Ji
                      </span>
                    </h2>
                    <p className="my-4 fs-4">
                      शिक्षा, भाषा, भाषा बोध, विषय बोध या सूचनाओं का संग्रह
                      मात्र नहीं है, अपितु इन सबके प्रमाणिक बोध के साथ स्वयं में
                      सन्निहित अनन्त ज्ञान संवेदना, असीम सामर्थ्य, शौर्य, वीरता,
                      पराक्रम एवं पुरुषार्थ को जानकर,सही दिशा में लगाकर स्वयं,
                      अपने परिवार, समाज, राष्ट्र एवं विश्व के लिए श्रेष्ठतम
                      योगदान देना है | विद्याभ्यास के साथ-साथ योगाभ्यास एवं
                      श्रेष्ठ व्रतों के अभ्यास से आत्मानुशासन, आत्म-रूपान्तरण,
                      आत्मप्रेरणा, आत्मस्पर्धा, आत्मबोध से आत्माएँ सन्निहित
                      बीजरूप सामर्थ्य को इतना विकसित कर लें कि ब्रह्माण्डीय
                      समस्त ऊर्जाएँ हमारे पिण्ड से अभिव्यक्त होने लगें ।
                      विद्याभ्यास, विविध कुशलताओं एवं दैवी संपदाओं से स्वयं के
                      जीवन को सुंदरतम रूप में विकसित करें। आपके शरीरबल, मनोबल,
                      बुद्धिबल एवं आत्मबल को पूर्ण विवेक, पूर्ण श्रद्धा एवं
                      पूर्ण पुरुषार्थ से के जागृत करने हेतु श्रेष्ठतम प्रशिक्षण,
                      श्रेष्ठतम प्रशिक्षकों द्वारा प्राप्त हो तथा इस कार्य हेतु
                      श्रेष्ठतम वातावरण उपलब्ध होना चाहिए | विद्यार्थी जीवन की
                      समस्त चुनौतियों, संघर्षों, संकटों से जूझते हुए विकल्प रहित
                      संकल्प, अखण्ड एवं प्रचण्ड पुरुषार्थ से स्वयं को विजयी
                      बनाएँ | बड़ी सोच, कड़ी मेहनत एवं दृढ़ संकल्प के साथ अपने
                      भीतर ऐसा व्यक्तित्व, चरित्र, नेतृत्व एवं व्यक्तित्व विकसित
                      करें कि वे एक श्रेष्ठतम विश्व नागरिक के रूप में स्वयं को
                      गढ़ सकें और अपनी दिव्य मेधा, प्रतिभा, पुरुषार्थ एवं
                      पराक्रम से नया सामाजिक, आर्थिक, राजनैतिक एवं आध्यात्मिक
                      तंत्र स्थापित कर सकें ।
                    </p>

                    <div className="fs-4 mt-5">
                      <a
                        href="https://www.facebook.com/swami.ramdev/?locale=hi_IN"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-facebook fs-3"></i>
                      </a>
                      <a
                        href="https://twitter.com/yogrishiramdev?lang=en"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-twitter-x fs-3"></i>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCX7SHIccUCj3S4cID-pM-Xg"
                        target="_blank"
                        className="me-3"
                      >
                        <i className="bi bi-youtube fs-3"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/swami.ramdev/?locale=hi_IN"
                        target="_blank"
                      >
                        <i className="bi bi-linkedin fs-3"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="py-3">
          <div className="container">
            <div className="row align-items-center py-lg-8">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="pe-lg-6">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    Why choose us
                  </span>
                  <h2 className="display-5 mt-4 mb-3 fw-bold">
                    Why
                    <u className="text-warning">
                      <span className="text-primary"> Acharyakulam</span>
                    </u>
                    ?
                  </h2>
                  <h3>
                    Acharyakulam merges spiritual guidance with cutting-edge
                    education.
                  </h3>
                  <div className="mt-6">
                    <div className="d-flex mb-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className="bi bi-star-fill text-primary mt-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                        </svg>
                      </div>
                      <div className="ms-3">
                        <h3 className="mb-2">Divine Mentorship</h3>
                        <p className="mb-0 fs-4">
                          Guided by spiritual leaders, Acharyakulam combines
                          spiritual wisdom with education.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mb-4">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          fill="currentColor"
                          className="bi bi-life-preserver mt-1 text-primary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm6.43-5.228a7.025 7.025 0 0 1-3.658 3.658l-1.115-2.788a4.015 4.015 0 0 0 1.985-1.985l2.788 1.115zM5.228 14.43a7.025 7.025 0 0 1-3.658-3.658l2.788-1.115a4.015 4.015 0 0 0 1.985 1.985L5.228 14.43zm9.202-9.202-2.788 1.115a4.015 4.015 0 0 0-1.985-1.985l1.115-2.788a7.025 7.025 0 0 1 3.658 3.658zm-8.087-.87a4.015 4.015 0 0 0-1.985 1.985L1.57 5.228A7.025 7.025 0 0 1 5.228 1.57l1.115 2.788zM8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"></path>
                        </svg>
                      </div>
                      <div className="ms-3">
                        <h3 className="mb-2">Modern Meets Ancient</h3>
                        <p className="mb-0 fs-4">
                          Blending ancient wisdom with modern science for a
                          comprehensive learning experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mt-4 mt-lg-0 position-relative">
                  <span className="position-absolute top-50 start-100 translate-middle mt-n8 d-xl-block d-none">
                    <a
                      href="/media/prospectus.pdf"
                      target="_blank"
                      className="rounded-pill fs-5 py-1 px-4 bg-primary text-white"
                    >
                      Prospectus
                    </a>
                  </span>
                  <span className="position-absolute top-50 start-0 translate-middle mt-8 d-xl-block d-none">
                    <span className="rounded-pill fs-5 py-1 px-4 bg-primary text-white">
                      Admission
                    </span>
                  </span>
                  <img
                    src="/assets/images/school.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative z-1">
          <div className="container">
            <div className="primary-gradient rounded-5 py-lg-6 py-5 px-5 d-flex justify-content-center text-center">
              <div className="col-md-9 col-12 mt-5">
                <span className="text-white ls-md text-uppercase fw-semibold">
                  Our Goal
                </span>
                <h2 className="display-4 text-white mt-3">
                  Building a Foundation of Values
                </h2>
                <p className="lead text-white opacity-75 px-lg-8 mb-6">
                  The importance of being a parent, we want to build up such a
                  foundation of sanskaras in the children of Acharyakulam
                </p>
                <div className="d-grid d-md-block mb-5">
                  <Link
                    to="/mission-vision"
                    className="btn btn-primary px-5 btn-md mb-2"
                  >
                    Read More
                  </Link>
                  <Link
                    to="/introduction"
                    className="btn btn-light px-5 btn-md ms-2 mb-2"
                  >
                    Why Acharyakulam
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="mt-n6">
          <div className="container">
            <div className="bg-dark rounded-bottom-5 px-lg-8 px-5 py-lg-4 py-4 mb-5">
              <div className="d-flex justify-content-between align-items-center mt-5">
                <h4 className="fs-5 text-white fw-normal mt-3 lh-1">
                  Give your child a Quality Education where Innovation Begins
                </h4>
                <Link
                  to="/"
                  className="btn btn-primary px-5 btn-md rounded-pill ms-3"
                >
                  Admission
                </Link>
              </div>
            </div>
          </div>
        </div>

        <section className="pb-4">
          <div className="container py-lg-2">
            <div className="row pb-5 mb-3">
              <div className="mt-4 text-center">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Our Gurus
                </span>
                <h2 className="display-4 mt-1 mb-0">
                  Our <span className="colorful-text">guiding</span> lights
                </h2>
                <p className="lead mb-0 px-lg-8">
                  Our Gurus, our guiding lights, leading us with wisdom and
                  care.
                </p>
              </div>
            </div>
            <div className="row align-items-center g-3">
              <div className="col-6">
                <div className="position-relative d-flex align-items-end justify-content-end">
                  <img
                    src="/assets/images/swamiji.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="400"
                  />
                  <div
                    type="button"
                    className="modal-close-button bg-white rounded-pill position-absolute m-3"
                    data-bs-toggle="modal"
                    data-bs-target="#messageSwamiRamdevjiModal"
                  >
                    <svg
                      className="svg svg-plus svg-plus-cutout"
                      viewBox="0 0 99.598 99.547"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.94 49.825C25.94 47.277 27.743 45.515 30.29 45.515H45.577V30.23C45.577 27.69 47.236 25.929 49.711 25.929 52.26 25.93 54.021 27.68 54.021 30.23V45.515H69.36C71.856 45.515 73.66 47.277 73.66 49.825 73.659 52.301 71.845 54.011 69.359 54.011H54.021V69.297C54.021 71.845 52.26 73.597 49.711 73.597 47.236 73.596 45.577 71.782 45.577 69.296V54.01H30.291C27.753 54.01 25.94 52.3 25.94 49.825Z"
                        fill="#000"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="position-relative d-flex align-items-end justify-content-end">
                  <img
                    src="/assets/images/acharyaji.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="400"
                  />
                  <div
                    type="button"
                    className="modal-close-button bg-white rounded-pill position-absolute m-3"
                    data-bs-toggle="modal"
                    data-bs-target="#messageAcharyajiModal"
                  >
                    <svg
                      className="svg svg-plus svg-plus-cutout"
                      viewBox="0 0 99.598 99.547"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.94 49.825C25.94 47.277 27.743 45.515 30.29 45.515H45.577V30.23C45.577 27.69 47.236 25.929 49.711 25.929 52.26 25.93 54.021 27.68 54.021 30.23V45.515H69.36C71.856 45.515 73.66 47.277 73.66 49.825 73.659 52.301 71.845 54.011 69.359 54.011H54.021V69.297C54.021 71.845 52.26 73.597 49.711 73.597 47.236 73.596 45.577 71.782 45.577 69.296V54.01H30.291C27.753 54.01 25.94 52.3 25.94 49.825Z"
                        fill="#000"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="pt-lg-2 pb-lg-7 py-10">
            <div className="container mb-2">
              <div className="row justify-content-center text-center">
                <div className="col-md-9 col-12 mt-5">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    Testimonial
                  </span>
                  <h2 className="display-4 mt-1">
                    Voices of Our{" "}
                    <span className="colorful-text">Students</span>
                  </h2>
                  <p className="lead px-lg-8 mb-6">
                    Hear directly from our students as they share their personal
                    experiences and insights.
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-5 overflow-hidden">
              <div className="row g-5">
                <div className="col-lg-6 col-12">
                  <img
                    src="./assets/images/photos/3.jpg"
                    className="w-100 rounded-end-5 object-fit-cover"
                    height="500"
                  />
                </div>
                <div className="col-lg-5 col-12 d-flex align-items-center">
                  <div
                    id="carouselTestimonialAutoplaying"
                    className="carousel slide px-lg-0 px-4"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators ms-lg-5 ms-4 d-flex justify-content-start">
                      <button
                        type="button"
                        data-bs-target="#carouselTestimonialAutoplaying"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselTestimonialAutoplaying"
                        data-bs-slide-to="1"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="p-lg-5 me-lg-5 d-flex mb-8 flex-column justify-content-center h-100">
                          <i className="bi bi-quote text-primary display-1"></i>
                          <p className="display-6 mb-4">
                            Supportive teachers and engaging lessons have helped
                            me discover my passions and build confidence.
                          </p>
                          <span className="text-primary text-uppercase fw-semibold">
                            KAJAL KUMARI
                          </span>
                          <span>8th Grade</span>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="p-lg-5 me-lg-5 d-flex mb-8 flex-column justify-content-center h-100">
                          <i className="bi bi-quote text-primary display-1"></i>
                          <p className="display-6 mb-4">
                            Acharyakulam is a fantastic place for learning and
                            personal growth, with supportive teachers and a
                            positive environment.
                          </p>
                          <span className="text-primary text-uppercase fw-semibold">
                            PAWAN RAJ
                          </span>
                          <span>9th Grade</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1">
                  <div className="d-lg-block d-none bg-primary rounded-start-5 w-100 h-100 ms-4"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-1">
          <div className="container">
            <div className="row align-items-center py-lg-3">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="pe-lg-6">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    OUR PRINCIPAL
                  </span>
                  <h2 className="display-5 mt-2 mb-3 fw-bold">
                    Mrs. Sujata Kaura
                  </h2>
                  <h3>
                    Our Principal is a dedicated leader committed to fostering a
                    positive and inspiring school environment.
                  </h3>
                  <div className="mt-6">
                    <p className="mb-0 fs-4">
                      As we embark on another chapter of our educational
                      odyssey, I am reminded of the profound words of Nelson
                      Mandela: "Education is the most powerful weapon which you
                      can use to change the world". Here at our esteemed
                      institution, we are dedicated to wielding this weapon
                      judiciously and seamlessly interwining ancient wisdom with
                      contemporary educational methodologies to empower
                      burgeoning minds and sculpt the leaders of tomorrow.
                    </p>
                    <Link
                      to="/principal"
                      className="btn btn-primary px-6 rounded-pill btn-md mt-4"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mt-4 mt-lg-0">
                  <img
                    src="/assets/images/principal.jpg"
                    alt="featured"
                    className="w-100 object-fit-cover rounded-5"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-1 pt-lg-4 pb-lg-7">
            <div className="row justify-content-center text-center pb-5 mb-3">
              <div className="col-md-8 col-12 mt-4">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Our Events
                </span>
                <h2 className="display-4 mt-1">
                  Event <span className="colorful-text">Highlights</span>
                </h2>
              </div>
            </div>
            <div className="row align-items-center g-3">
              <div className="col-lg-7 col-md-6 col-12">
                <div>
                  <div
                    id="carouselevents1"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/assets/images/gallery/1.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/gallery/2.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/gallery/3.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselevents1"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselevents1"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div>
                  <div
                    id="carouselevents3"
                    className="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="/assets/images/gallery/5.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/gallery/6.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="/assets/images/gallery/7.jpg"
                          className="d-block w-100 rounded-5 object-fit-cover"
                          height="400"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselevents3"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselevents3"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5">
          <div className="container">
            <div className="row align-items-center pb-lg-6">
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-4 mb-lg-0">
                  <img
                    src="/assets/images/photos/6.jpg"
                    alt="featured"
                    className="w-100 rounded-5 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">
                <div className="ps-lg-7">
                  <span className="text-primary ls-md text-uppercase fw-semibold">
                    OUR INFRASTRUCTURE
                  </span>
                  <h2 className="display-5 mt-4 mb-3 fw-bold">
                    Highlights of Our Campus Infrastructure
                  </h2>
                  <h3>
                    Explore the key features of our campus facilities,
                    showcasing the modern and well-equipped spaces
                  </h3>
                  <div className="mt-5 row">
                    <div className="col">
                      <ul className="list-unstyled fs-4 fw-medium">
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Library</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Laboratories</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Assembly Ground</span>
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="list-unstyled fs-4 fw-medium">
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Art Room</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Music Room</span>
                        </li>
                        <li className="mb-2 d-flex">
                          <i className="bi bi-check-circle-fill text-success me-2"></i>
                          <span>Labs</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-7 mb-4 overflow-hidden">
          <div className="container">
            <div className="row pb-3 mb-3">
              <div className="col-md-9 col-12 mt-4">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Media
                </span>
                <h2 className="display-4 mt-1">
                  Our <span className="colorful-text">Media</span> Gallery
                </h2>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-lg-3 col-6">
              <div className="h-100">
                <img
                  src="./assets/images/photos/7.jpg"
                  className="w-100 rounded-end-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
            <div className="col-lg-6 col-6 d-lg-block d-none">
              <div className="h-100">
                <img
                  src="./assets/images/photos/4.jpg"
                  className="w-100 rounded-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
            <div className="col-lg-3 col-6">
              <div className=" h-100">
                <img
                  src="./assets/images/photos/11.jpg"
                  className="w-100 rounded-start-5 object-fit-cover"
                  height="500"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-7 mb-5 overflow-hidden">
          <div className="container">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-12">
                <span className="text-primary ls-md text-uppercase fw-semibold">
                  Some FAQs
                </span>
                <h2 className="display-4 mt-3">
                  Frequently Asked{" "}
                  <span className="colorful-text">Questions</span>
                </h2>
                <p className="lead  px-lg-8 mb-8">
                  Get answers to common questions about our programs,
                  admissions, and school life.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 pb-lg-0 pb-5 d-flex align-items-center">
                <div
                  className="accordion accordion-flush w-100 pe-lg-5 pe-0 me-lg-5 me-0"
                  id="faqs"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="true"
                        aria-controls="flush-collapseOne"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          How do I enroll my child in school?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#faqs"
                      style={{}}
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Visit the admission section of our website for forms
                          and detailed instructions. You may also call the
                          office for assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          Are extracurricular activities offered?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqs"
                      style={{}}
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Yes, the school offers a variety of extracurricular
                          activities, including sports, arts, and clubs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseThree"
                        aria-expanded="false"
                        aria-controls="flush-collapseThree"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          Student discipline procedure?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseThree"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqs"
                      style={{}}
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Discipline issues are addressed according to the
                          school’s code of conduct, with actions ranging from
                          warnings to suspension, depending on the severity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button px-0 bg-white collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                      >
                        <p className="faqs-text text-dark fw-medium m-0">
                          School’s attendance policy?
                        </p>
                      </button>
                    </h2>
                    <div
                      id="flush-collapseFour"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqs"
                    >
                      <div className="accordion-body px-0">
                        <p className="fs-4 fw-light mb-4 text-secondary">
                          Students are expected to attend all classes. Frequent
                          absences require a valid excuse and may impact
                          academic performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-12">
                <div className="mb-4 mb-lg-0">
                  <img
                    src="/assets/images/photos/9.jpg"
                    alt="featured"
                    className="w-100 rounded-5 object-fit-cover"
                    height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
